import { ActionFunctionArgs, LoaderFunctionArgs, json } from "@remix-run/node";
import {
  Form,
  useLoaderData,
  useSearchParams,
  useSubmit,
} from "@remix-run/react";
import { useEffect } from "react";
import {
  browserSupportsWebAuthn,
  browserSupportsWebAuthnAutofill,
  handleFormSubmit,
  startAuthentication,
} from "remix-auth-webauthn/browser";
import { z } from "zod";
import { zfd } from "zod-form-data";
import { KeyIcon } from "~/components/icons/KeyIcon";
import { Alert, AlertDescription, AlertTitle } from "~/components/ui/alert";
import { Button, ShinyButton } from "~/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "~/components/ui/card";
import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { Separator } from "~/components/ui/separator";
import { auth } from "~/sessions/auth/auth";
import { webAuthnStrategy } from "~/sessions/auth/webauthn-strategy";
import { commitSession, getSession } from "~/sessions/sessions";

const params = zfd.formData({
  method: z.enum(["email", "passkey"]),
});

export const loader = async ({ request }: LoaderFunctionArgs) => {
  await auth.isAuthenticated(request, { successRedirect: "/home" });
  const options = await webAuthnStrategy.generateOptions(request, null);

  // The webauthn strategy extracts this automatically
  const session = await getSession(request.headers.get("Cookie"));
  session.set("auth:challenge", options.challenge);

  return json(
    { options },
    {
      headers: {
        "Set-Cookie": await commitSession(session),
        "Cache-Control": "no-store",
      },
    },
  );
};

export const action = async ({ request }: ActionFunctionArgs) => {
  const { method } = params.parse(new URL(request.url).searchParams);
  const strategy = method === "email" ? "email-link" : "webauthn";

  const session = await getSession(request.headers.get("Cookie"));
  const challenge = session.get("auth:challenge");

  await auth.authenticate(strategy, request, {
    successRedirect: "/auth/post-login",
    failureRedirect: `/login?failed=${method}`,
    context: { challenge },
  });
  return json({});
};

export default function Login() {
  const { options } = useLoaderData<typeof loader>();
  const [params] = useSearchParams();
  const failedMethod = params.get("failed");

  const submit = useSubmit();

  useEffect(() => {
    async function autofill() {
      if (!browserSupportsWebAuthn()) return;
      if (!(await browserSupportsWebAuthnAutofill())) return;

      startAuthentication(options, true).then((response) => {
        const data = new FormData();
        data.set("intent", "authentication");
        data.set("response", JSON.stringify(response));
        data.set("type", "authentication");
        submit(data, {
          method: "post",
          action: "/login?method=passkey",
        });
      });
    }

    autofill();
  }, []);

  return (
    <div className="min-h-dvh bg-backdrop flex flex-col items-center justify-center gap-6">
      <div className="flex gap-4">
        <img src="/logo.png" alt="Cork" className="size-8" />
        <span className="text-xl font-bold">Covid Chemistry</span>
      </div>
      <Card className="p-10 max-w-md w-full space-y-6">
        <form className="space-y-4" method="post" action="/login?method=email">
          <CardHeader>
            <CardTitle className="text-2xl">Log in or sign up</CardTitle>
            <CardDescription>
              Enter your email below to continue
            </CardDescription>
          </CardHeader>

          {failedMethod && (
            <CardContent>
              {failedMethod === "email" ?
                <Alert variant="destructive">
                  <AlertTitle>Failed to log in with that email</AlertTitle>
                </Alert>
              : <Alert variant="destructive">
                  <AlertTitle>Failed to log in with that passkey</AlertTitle>
                  <AlertDescription>
                    Before you can use a passkey, you need to log in and
                    register it with your account.
                  </AlertDescription>
                </Alert>
              }
            </CardContent>
          )}

          <CardContent className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="email@example.com"
                required
                autoComplete="webauthn"
              />
            </div>
            <Button type="submit" className="w-full">
              Continue with email
            </Button>
          </CardContent>
        </form>

        <div className="text-xs text-gray-500 px-6">
          You must be over 18 to use this site.
        </div>
      </Card>
    </div>
  );
}
